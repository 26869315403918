import anime from "animejs";

export default (callback?: () => void) => {
  const scrollElement =
    window.document.scrollingElement ||
    window.document.body ||
    window.document.documentElement;

  anime({
    targets: scrollElement,
    scrollTop: 0,
    duration: 800,
    easing: "easeInOutQuad",
    complete: callback
  });
};
