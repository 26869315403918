import template from "./template";
import { isArray } from "./array";
import { StyleSides } from "../defaults";

export { template };

export const styleСonversionSides = (prop: StyleSides) => {
  if (isArray(prop)) {
    let result = "";
    for (let i = 0; i < (prop as number[]).length; i++) {
      if (i < 4) {
        result += `${(prop as number[])[i]}px `;
      } else {
        break;
      }
    }
    return result;
  }

  return typeof prop === "string" ? prop : prop + "px";
};
