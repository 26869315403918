import { GetterTree } from "vuex";

import { ITableState } from "./table.interfaces";
import { RootState } from "@/store/root.interface";
import { CONTAINER_PADDINGS } from "@/constants";
import { ROLES } from "../authorization/authorization.constants";

export const getters: GetterTree<ITableState, RootState> = {
  getTableWidth(state) {
    return {
      app: state.containerWidth["app"] - CONTAINER_PADDINGS,
      preapp: state.containerWidth["preapp"] - CONTAINER_PADDINGS
    };
  },

  getColumnTitles(state, getters, rootState, rootGetters) {
    const role = rootGetters["authorizationState/getRole"];
    if (state.columns[state.contentType].hasOwnProperty(role)) {
      return state.columns[state.contentType][role].map((c: any) => c.title);
    } else {
      return (state.columns[state.contentType] as any)[ROLES.Default.name].map((c: any) => c.title);
    } 
  },
}
