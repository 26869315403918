import { fetch } from "@/services/api";
import router from "@/router";
import Vue from "vue";
import { ActionTree } from "vuex";
import {POSITION} from "vue-toastification";

import {logError} from "@/services/api/serverLogger";

import { RootState } from "@/store/root.interface";
import { CommentsState, CommentsRequestType } from "./interfaces/comments.state";

import {
  SET_COMMENTS,
  SET_LAST_COMMENT,
  SET_COMMENTS_LOADED,
} from "./constants/mutations-type";

type GetCommentsArgs = {
  type: CommentsRequestType;
  id: number | string;
}

type AddCommentArgs = {
  type: CommentsRequestType;
  orderId: number | string;
  commentText: string;
}

export const actions: ActionTree<CommentsState, RootState> = {
  async getComments({ commit }: any, {type, id}: GetCommentsArgs) {
    commit(SET_COMMENTS_LOADED, false);
    try {
      const url = type === 'app' ? 
        `api/initialApplication/getComments/${id}` : 
        `api/InternalPreApplication/getComments/${id}`;

      const res = await fetch.get(url);

      if (res && res.status == 200) {
       const {comments} = res.data;
        if (comments) {
          commit(SET_COMMENTS, comments);
        } else {
          throw new Error(`${url}, status 200, отсутствует comments в response`);
        }
      } else {
        throw new Error(`${url}`);
      }
    } catch (err) {
      router.push("/");

      logError((err as any), `Ошибка при загрузке комментариев в ${type == 'app' ? 'заявке' : 'предзаявке'} ${id}`);
      let mess = `Ошибка при загрузке комментариев в ${type == 'app' ? 'заявке' : 'предзаявке'} ${id}`;

      if ((err as any).data && (err as any).data.message) {
          mess = (err as any).data.message;
      }
      Vue.$toast.error(mess, {
          position: POSITION.TOP_LEFT,
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
      });
    } finally {
      commit(SET_COMMENTS_LOADED, true);
    }
  },

  async addComment({ commit }: any, {type, orderId, commentText}: AddCommentArgs) {
    try {
      const url = type === 'app' ? 
        `api/initialApplication/createComment` : 
        `api/InternalPreApplication/createComment`;

      const res = await fetch.post(url, {commentText, orderId});
      if (res && res.status == 200) {
        const {name, position, date, text} = res.data;
        if (name && position && date && text) {
          commit(SET_LAST_COMMENT, {name, position, date, text});
          type === "preapp" && commit("preApplicationState/SET_MANAGERCOMMENT", text, {root: true});
        }
        else {
          throw new Error(`${url}, status 200, отсутствуют данные в response`);
        }
      } else {
        throw new Error(`${url}`);
      }
    } catch (err) {
      logError((err as any), `Ошибка при сохранении комментария в ${type == 'app' ? 'заявке' : 'предзаявке'} ${orderId}`);
      let mess = `Ошибка при сохранении комментария в ${type == 'app' ? 'заявке' : 'предзаявке'} ${orderId}`;

      if ((err as any).data && (err as any).data.message) {
          mess = (err as any).data.message;
      }
      Vue.$toast.error(mess, {
          position: POSITION.TOP_LEFT,
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
      });
    }
  },

  getLastComment({commit, state}: any, text: string) {
    commit(SET_LAST_COMMENT, {...state.lastComment, text});
  },

  clearCommentsState({commit}: any) {
    commit(SET_COMMENTS, []);
    commit(SET_LAST_COMMENT, {name: '', position: '', date: '', text: ''});
  },
}
