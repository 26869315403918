import { GetterTree } from "vuex";
import { isEqual, isEmpty } from "lodash";

import { IApplicationListState } from "./application-list.interfaces";
import { RootState } from "../../root.interface";

import { DEFAULT_FILTER } from "./application-list.defaults";
import { getFormattedDate } from "@/helpers";
import { IDENTIFICATION_KINDS } from "@/constants";

export const getters: GetterTree<IApplicationListState, RootState> = {
  getApplicationList(state, getters, rootState, rootGetters) {
    const isManagerPartner = rootGetters["authorizationState/isManagerPartner"];
    const isChiefManager = rootGetters["authorizationState/isChiefManager"];
    return state.applicationsList.map((el: any) => {
      const statusSMEV = Array.isArray(el.statusSMEV) ? el.statusSMEV.map((item: any) => ({
        ...item,
        name: item.name.replace(" заявителя", "")
      })) : [];

      const row = [];
      row.push({ id: el.id || "", externalSourcePkcs10: el.externalSourcePkcs10, managerComment: el.managerComment || ""});
      row.push(el.creationDate || "");
      row.push({
        status: (el.status && el.status == "Ошибка регистрации в ЭДО" ? "Регистрация в ЭДО" : el.status) || "",
        comment: isEmpty(el.comment) ? null : el.comment,
        statusSMEV
      });
      row.push(el.paymentStatusDescription || "");
      if (!isManagerPartner) {
        row.push(el.lastPaymentDate ? getFormattedDate(el.lastPaymentDate, "DD.MM.YYYY HH:mm") : "");
      }
      row.push({
        organizationShortName: el.organizationShortName || "",
        fnsStatusId: el.fnsStatusId || 0
      });
      row.push(`${el.surname || ""} ${el.name || ""} ${el.middleName || ""}`);
      row.push(el.applicantType || "");
      if (!isManagerPartner) {
        row.push(el.phoneNumber || "");
      }
      row.push(el.order || "");
      if (!isManagerPartner) {        
        row.push(`<b>${!el.totalPrice && el.totalPrice !== 0 ? "" : el.totalPrice} ₽</b>`);
      }
      if (isChiefManager || isManagerPartner) {
        row.push(el.managerFIO || "");
      }
      if (isManagerPartner) {
        row.push(el.filialName || "");
      }
      return row;
    });
  },

  getFilter(state): string {
    const result: string[] = [];

    Object.keys(state.saveFilterControls).forEach(name => {
      let val = state.saveFilterControls[name];

      if (
        val && !(Array.isArray(val) && !val.length) || 
        (typeof val === "string" && !isEmpty(val))
      ) {
        if (~name.indexOf("date")) {
          val = getFormattedDate(val, "L");
        }
        if (Array.isArray(val) && val.length) {
          val = val.join(".");
        }
        if (name === "identificationKind") {
          --val;
        }
        result.push(`${name}=${val}`);
      }
    });

    if (state.isArchive) {
      result.push("statusArchive=true");
    }

    return result.length ? "?" + result.join("&") : "";
  },

  getFilterInfoString(state) {
    const applicants: string[] = [];
    const statuses: string[] = [];
    const createDates: string[] = [];
    const issueDates: string[] = [];
    const paymentDates: string[] = [];
    const managers: string[] = [];
    const organizations: string[] = [];
    let identificationKind: string = "";

    const applicantNames: {[key: string]: string} = {
      fl: "Физическое лицо",
      ip: "Индивидуальный предприниматель",
      ur: "Юридическое лицо"
    };
    const statusNames: {[key: string]: string} = {
      statusSendDoc: "Отправка документов",
      statusGenReq: "Генерация запроса",
      statusRelease: "Выпуск",
      statusDone: "Готово",
      statusFail: "Отказ",
      statusPayed: "Оплачено",
      statusWaitingOriginals: "Ожидание оригиналов",
      statusOriginalsIncorrect: "Оригиналы некорректны",
      statusClosed: "Завершено",
    };
    const dateCreateNames: {[key: string]: string} = {
      dateCreateFrom: "с",
      dateCreateTo: "по",
    };
    const dateIssueNames: {[key: string]: string} = {
      dateIssueFrom: "с",
      dateIssueTo: "по",
    };
    const datePaymentNames: {[key: string]: string} = {
      datePaymentFrom: "с",
      datePaymentTo: "по",
    };

    Object.keys(state.saveFilterControls).forEach(name => {
      let val = state.saveFilterControls[name];

      if (val) {
        if (Object.keys(dateCreateNames).includes(name)) {
          val = getFormattedDate(val, "L");
          createDates.push(`${dateCreateNames[name]} ${val}`);
        }
        if (Object.keys(dateIssueNames).includes(name)) {
          val = getFormattedDate(val, "L");
          issueDates.push(`${dateIssueNames[name]} ${val}`);
        }
        if (Object.keys(datePaymentNames).includes(name)) {
          val = getFormattedDate(val, "L");
          paymentDates.push(`${datePaymentNames[name]} ${val}`);
        }
        if (Object.keys(applicantNames).includes(name)) {
          applicants.push(applicantNames[name]);
        }
        if (Object.keys(statusNames).includes(name)) {
          statuses.push(statusNames[name]);
        }
        if (name == "managers") {
          val.forEach((item: any) => {
            managers.push(state.filterManager.find(m => m.value == item)?.label);
          })
        }
        if (name == "organizations") {
          val.forEach((item: any) => {
            organizations.push(state.filterOrganizations.find(m => m.value == item)?.label);
          })
        }
        if (name == "identificationKind") {
          identificationKind = Object.values(IDENTIFICATION_KINDS).find(o => o.value === val)!.label;
        }
      }
    });
    return {
      applicants: applicants.join(", "),
      statuses: statuses.join(", "),
      identificationKind,
      createDates: createDates.join(" "),
      issueDates: issueDates.join(" "),
      paymentDates: paymentDates.join(" "),
      managers: managers.join(", "),
      organizations: organizations.join(", ")
    };
  },

  isActiveFilter(state) {
    return !isEqual(state.saveFilterControls, DEFAULT_FILTER);
  }
};
