import Vue from "vue";
import Router from "vue-router";
import cookie from "js-cookie";
import store from "./store";
import {app} from "@/main";

import {
    CLEAR_APPLICATION_LIST,
    SET_APPLICATIONS_LIST
} from "./constants/mutations-type";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/about",
            name: "about",
            component: () => import("@/views/About.vue")
        },
        {
            path: "/",
            name: "application",
            component: () => import("@/views/Application.vue")
        },
        {
            path: "/403",
            name: "403",
            meta: {requiresAuth: true},
            component: () => import("@/views/NotFound.vue"),
            props: {default: true, typeError: "403"}
        },
        {
            path: "*",
            name: "404",
            meta: {requiresAuth: true},
            component: () => import("@/views/NotFound.vue")
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (store.state.updateExists) {
        store.dispatch("refreshApp");
    }

    next();
    
});

export default router;
