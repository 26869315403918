import { fetch } from "@/services/api";

export const logErrorText = (message: string) => {
  log(message, true);
};

export const logError = (error: Error, name: string = "") => {
  log(`${name}, message: ${error.message}, stack: ${error.stack}`, true);
};

export const logMessage = (message: string) => {
  log(message, false);
};

const log = (message: string, isError: boolean) => {
  fetch.post("/api/FilialClient/log", { isError, message, source: "лккл" });
};
