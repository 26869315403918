import { fetch } from "@/services/api";
import { logError } from "@/services/api/serverLogger";
import { ActionTree } from "vuex";

import { RootState } from "@/store/root.interface";
import { CertState } from "./interfaces/cert.state";

import {
  SET_CERT_REQUEST_FILE,
  CLEAR_CERT_REQUEST_FILE
} from "./constants/mutations-type";

const toBase64 = (file:any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const actions: ActionTree<CertState, RootState> = {
  parseCertRequest: {
    root: true,

    handler: async ({ commit }, file) => {
      const body = new FormData();

      if (!file) {
        return Promise.reject();
      }

      try {
        body.append("certRequest", file);

        const { data, status } = await fetch.post(
          "api/InitialApplication/parse",
          body
        );

        if (status === 200) {
          //commit(SET_CERT_REQUEST_FILE, await file.text());
          commit(SET_CERT_REQUEST_FILE,await toBase64(file));

          return Promise.resolve(data.fields);
        }
      } catch (error) {
        logError(error, "Ошибка отправки файла запроса на парсинг");
        throw error;
      }
    }
  },
  clearCertRequest: {
    root: true,
    handler: ({ commit }) => {
      commit(CLEAR_CERT_REQUEST_FILE);
    }
  }
};
