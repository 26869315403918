import {GetterTree} from "vuex";
import moment from "moment";
import {isEmpty} from "lodash";
import {crypto} from "./index";

import {IApplicationState, TControlName, TTypeEntre, IFieldUpload} from "./application.interfaces";
import {RootState} from "@/store/root.interface";
import {
    ANOTHER_UPLOADS_MAX_COUNT,
    DOWNLOAD_DOCUMENTS,
    STATUSES,
    PAYER_TYPES,
    APPLICANT_TYPES
} from "./application.constants";
import { DEFAULT_ANOTHER_UPLOAD } from "./application.defaults";
import { SHOW_FILIAL_CLIENT_LINK } from "@/constants";
import { InitialFileTypes } from "@/constants/InitialFileTypes";


const typeEntreLabel = (typeEntreValue: TTypeEntre) => {
    if (!Object.keys(APPLICANT_TYPES).includes(typeEntreValue)) {
        return "";
    }
    return APPLICANT_TYPES[typeEntreValue].label;
};

export const getters: GetterTree<IApplicationState, RootState> = {
    getVisibleBodyForm(state) {
        return !isEmpty(state.formControls.typeEntre.value);
    },

    getDocumentsToUpload(state: any) {
        const uploads = state.uploadFormControls;
        const addovert = state.formControls.addovert;
        const needToAttachRevoke = state.needToAttachRevoke;
        const identifikationKind = state.formControls.identificationKind;
        const result: any[] = [];
        Object.keys(uploads).forEach(name => {
            if (
                !([
                    "application",
                    "applicationSignature",
                    "passport",
                    "foto",
                    "contract",
                    "proxy",
                    "revoke"
                ].includes(name) && state.isDocumentsCollected) &&
                !uploads[name].isCollected &&
                (!["contract", "revoke", "certificateSigned", "securityInformationSigned", "extra", "applicationSignature"].includes(name) ||
                (name === "revoke" && needToAttachRevoke) ||
                (name === "contract" && +addovert.value === 0) ||
                (name === "applicationSignature" && +identifikationKind.value === 2) ||
                ((name === "certificateSigned" || name === "securityInformationSigned") &&
                [STATUSES.CertificateReady.id, STATUSES.WaitingForOriginals.id, STATUSES.OriginalsIncorrect.id].includes(state.statusId)))
            ) {
                result.push({...uploads[name], name});
            }
        });
        return result;
    },

    getCollectedDocuments(state: any) {
        const uploads = state.uploadFormControls;
        const addovert = state.formControls.addovert;
        const needToAttachRevoke = state.needToAttachRevoke;
        const identifikationKind = state.formControls.identificationKind;
        const sigCheckStatus = state.sigCheckStatus;
        const sigIssuerCN = state.sigIssuerCN;
        const result: any[] = [];
        Object.keys(uploads).forEach(name => {
            if (
                uploads[name].isCollected &&
                (!["contract", "revoke", "applicationSignature"].includes(name) ||
                (name === "revoke" && needToAttachRevoke) ||
                (name === "contract" && +addovert.value === 0) ||
                (name === "applicationSignature" && +identifikationKind.value === 2))
            ) {
                if(identifikationKind.value === 2 && (name === "applicationSignature" || name === "application")){
                    if(name === "application" && !uploads["applicationSignature"].isCollected || name === "applicationSignature"){
                        let fileItem: IFieldUpload = uploads[name];
                        fileItem.descr = sigCheckStatus+", издатель: "+sigIssuerCN;
                        result.push({...fileItem,name,});
                    } else {
                        result.push({...uploads[name], name,});    
                    }
                } else {
                    result.push({...uploads[name], name,});
                }
            }
        });
        return result;
    },

    getAnotherDocuments(state: any) {
        const uploads = state.anotherUploads;
        const result: any[] = [];
        let anotherUploadsCount: number = 0;
        Object.keys(uploads).forEach(name => {
            if (uploads[name].isTouched) {
                result.push({...uploads[name], name});
                anotherUploadsCount++;
            }
        })
        if (!result.find((item: any) => !item.isTouched) &&
            anotherUploadsCount < ANOTHER_UPLOADS_MAX_COUNT)
        {
            result.push({...DEFAULT_ANOTHER_UPLOAD, name: `another${anotherUploadsCount}`});
        }
        return result;
    },

    getApplicationInfo(state: any) {
        return {
            status: state.status,
            statusId: state.statusId,
            orderId: state.orderId,
            creationDate: moment(state.creationDate)
                .locale("ru")
                .format("LLL"),
            changeDate: moment(state.changeDate)
                .locale("ru")
                .format("LLL"),
            typeEntre: typeEntreLabel(state.formControls.typeEntre.value),
            paymentStatusDescription: state.paymentStatusDescription,
            lastPaymentDate: state.lastPaymentDate ?
                ' ' + moment(state.lastPaymentDate)
                    .locale("ru")
                    .format("DD.MM.YYYY HH:mm")
                : null,
        };
    },

    getBrowserPluginEnabled() {
        return crypto.browserPluginEnabled;
    },

    getApplicationEmail(state: any) {
        return state.formControls.email.value;
    },

    newPreAppNum(state,getters,rootState){
        return rootState.newPreAppNum;
    },

    getIsSystemProcessing(state) {
        return state.statusId === STATUSES.SystemProcessing.id;
    },
    
    getIsFormValid(state) {
        let isFormValid = true;
        Object.keys(state.formControls).forEach(controlName => {
            const control: any = state.formControls[controlName as TControlName];
            if (control.hasOwnProperty("isValid") && control.active) {
                isFormValid = control.isValid && isFormValid;
            }
        });
        return isFormValid;
    },

    getFnsExistingCerts(state) {
        const certs = state.fnsExistingCerts.map(((cert: any) => ({
            ...cert, issued: cert.issued.replace(/\s\d\d:\d\d:\d\d/, "")
        })))
        return certs;
    },

    getDownloadDocuments(state) {
        const isFNSIssueAvailable = state.isFNSIssueAvailable;
        return state.filesDownload
            .filter((file:any) => {
                return (
                    (file.typeId !== InitialFileTypes.Procuration) &&
                    (file.typeId !== InitialFileTypes.InsurancePolicy) && // пока не отдаём в ЛККЛ полис страхования, нужна отдельная задача
                    (file.typeId !== InitialFileTypes.Revocation) ||
                    (file.typeId === InitialFileTypes.Revocation && isFNSIssueAvailable)
                );
            })
            .map((file: any) => {
                for (const doc of DOWNLOAD_DOCUMENTS) {
                    if (doc.typeId === file.typeId) {
                        const { descr, ...download } = doc;
                        return {
                            ...download,
                            ...file,
                            descr: descr
                                .replace("%%", file.orderId)
                                .replace("%1%", file.fileMarker)
                        };
                    }
                }
            });
    },

    getIsRevokeUploaded(state) {
        return state.uploadFormControls["revoke"].files
            && !!state.uploadFormControls["revoke"].files.length
            && state.uploadFormControls["revoke"].isValid;
    },

    getApplicationStatuses() {
        return STATUSES;
    },

    getPayerTypeOptions() {
        return Object.values(PAYER_TYPES);
    },

    getIsFilledByOGRN(state) {
        return state.isFilledByOGRN;
    },

    getIsReadonlyKeys(state) {
        return !isEmpty(state.fieldsReadonlyKeys);
    },

    getAddressFNS(state) {
        return state.fieldsFromBasisApi.addressFNS;
    },

    getIsDocumentsReadyToUpload(state, getters) {
        let result = true;
        const addovert = state.formControls.addovert;
        const needToAttachRevoke = state.needToAttachRevoke;
        const identifikationKind = state.formControls.identificationKind;
        if (getters.getDocumentsToUpload.length === 1 && !getters.getDocumentsToUpload[0].isTouched) {
            return false;
        }
        getters.getDocumentsToUpload.forEach((item: any) => {
            if (
                (item.required && !item.isTouched) ||
                (item.isTouched &&
                (!["contract", "revoke", "applicationSignature"].includes(item.name)
                || (item.name === "revoke" && needToAttachRevoke)
                || (item.name === "contract" && +addovert.value === 0)
                || (item.name === "applicationSignature" && +identifikationKind.value === 2))
                && (!item.isValid || !!item.errors.length))
            ) {
                result = false; 
            }
        })
        return result;
    },

    getTypeEntreValue(state) {
        return state.formControls.typeEntre.value;
    },

    getShowFilialClientLink() {
        return SHOW_FILIAL_CLIENT_LINK==="true"
    }
};
