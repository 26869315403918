import { Module } from "vuex";

import { actions } from "./authorization.actions";
import { mutations } from "./authorization.mutations";
import { getters } from "./authorization.getters";

import { IAuthorizationState } from "./authorization.interfaces";
import { RootState } from "@/store/root.interface";
import { ROLES } from "./authorization.constants";

import { defaultFormControls } from "./authorization.defaults";

const namespaced: boolean = true;

export const authorizationState: Module<IAuthorizationState, RootState> = {
  namespaced,
  state: {
    isRecoveryPassword: false,
    isFormValid: true,
    isSubmitActive: false,
    errorMessage: null,
    saveUser: false,
    formControls: { ...defaultFormControls },
    locationToRedirect: "",
    role: ROLES.Default.name
  },
  mutations,
  actions,
  getters
};
