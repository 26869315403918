export const defaultColumns: {[key: string]: {[key: string]: Array<{title: string, part: number}>}}= {
  app: {
    Default: [
      {title: "№", part: 0.06},
      {title: "Создана", part: 0.06},
      {title: "Статус", part: 0.07},
      {title: "Оплата", part: 0.06},
      {title: "Дата оплаты", part: 0.07},
      {title: "Организация", part: 0.09},
      {title: "ФИО", part: 0.08},
      {title: "Тип заявителя", part: 0.1},
      {title: "Телефон", part: 0.07},
      {title: "В заказе", part: 0.25},
      {title: "Итоговая стоимость", part: 0.09},
    ],
    ChiefManager: [
      {title: "№", part: 0.06},
      {title: "Создана", part: 0.06},
      {title: "Статус", part: 0.07},
      {title: "Оплата", part: 0.06},
      {title: "Дата оплаты", part: 0.07},
      {title: "Организация", part: 0.09},
      {title: "ФИО", part: 0.08},
      {title: "Тип заявителя", part: 0.1},
      {title: "Телефон", part: 0.07},
      {title: "В заказе", part: 0.18},
      {title: "Итоговая стоимость", part: 0.09},
      {title: "Менеджер", part: 0.07},
    ],
    ManagerPartner: [
      {title: "№", part: 0.06},
      {title: "Создана", part: 0.06},
      {title: "Статус", part: 0.07},
      {title: "Оплата", part: 0.06},
      {title: "Организация", part: 0.09},
      {title: "ФИО", part: 0.08},
      {title: "Тип заявителя", part: 0.1},
      {title: "В заказе", part: 0.25},
      {title: "Менеджер", part: 0.07},
      {title: "Филиал", part: 0.16},
    ],
  },
  preapp: {
    Default: [
      {title: "№", part: 0.06},
      {title: "Создана", part: 0.06},
      {title: "Статус", part: 0.07},
      {title: "Организация", part: 0.09},
      {title: "ФИО", part: 0.08},
      {title: "Тип заявителя", part: 0.1},
      {title: "Телефон", part: 0.07},
      {title: "В заказе", part: 0.38},
      {title: "Итоговая стоимость", part: 0.09},
    ]
  },
  certificates: {
    Default: [
      {title: "№", part: 0.06},
      {title: "Заявка", part: 0.06},
      {title: "Статус", part: 0.06},
      {title: "Организация", part: 0.34},
      {title: "ФИО", part: 0.34},
      {title: "Дата выдачи", part: 0.07},
      {title: "Дата завершения", part: 0.07}
    ]
  }
};
