import { GetterTree } from "vuex";

import { IAuthorizationState, TRoleName } from "./authorization.interfaces";
import { RootState } from "@/store/root.interface";
import { ROLES } from "./authorization.constants";

export const getters: GetterTree<IAuthorizationState, RootState> = {
  getFormControls(state: any) {
    if (state.isRecoveryPassword) {
      return {
        email: { ...state.formControls.email }
      };
    }

    return state.formControls;
  },
  getRole(state): TRoleName {
    if (state.role === ROLES.ChiefManager.name || state.role === ROLES.ManagerPartner.name) {
      return state.role;
    }
    return ROLES.Default.name;
  },
  isChiefManager(state) {
    return state.role === ROLES.ChiefManager.name;
  },
  isManagerPartner(state) {
    return state.role === ROLES.ManagerPartner.name;
  },
};
