export default (tpl: string, options: any) => {
  tpl = tpl.replace(/\{#[^]*?#\}/g, "");

  const matches = tpl.match(/\{\%\s*[a-zA-Z0-9._/:-]+?\s*\%\}/g);

  if (matches !== null) {
    matches.forEach(piece => {
      for (const key in options) {
        if (new RegExp(key).test(piece)) {
          tpl = tpl.replace(piece, options[key]);
        }
      }
    });
  }

  return tpl;
};
