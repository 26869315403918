import {POSITION} from "vue-toastification";

export const BASE_URL_API = process.env.VUE_APP_BASE_URL_API + "/";
export const BASE_URL_AUTH = process.env.VUE_APP_BASE_URL_AUTH + "/";
export const SHOW_FILIAL_CLIENT_LINK = process.env.VUE_APP_SHOW_FILIAL_CLIENT_LINK+"";

// FILTER
export const DATE_FORMAT = "dd.MM.yyyy";

// STYLES
export const MIN_CONTAINER_WIDTH = 1400;
export const MAX_CONTAINER_WIDTH = 1900;
export const CONTAINER_PADDINGS = 30;

// TOAST
export const TOAST_OPTIONS: any = {
  Error: {
    position: POSITION.TOP_LEFT,
    timeout: 6000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
  },
  Success: {
    position: POSITION.TOP_LEFT,
    timeout: 6000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
  }
};

// COMMON
export const IDENTIFICATION_KINDS = {
  Default: {
    value: 0, // в БД null
    label: "Не выбран"
  },
  Personaly: {
    value: 1, // в БД 0
    label: "Лично"
  },
  CurrentSign: {
    value: 2, // в БД 1
    label: "По действующей ЭП"
  }
};
