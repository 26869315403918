import EventEmitter from "../core/event-emitter";
import { TIME_START, TIME_END, TIME_STOP } from "../constants/events";

export default class Timer {
  private timerId: ReturnType<typeof setTimeout> | null;
  private remaining: number;
  private timeEnd: number | null;
  private active: boolean;
  private _event: EventEmitter;

  constructor(private time: number) {
    this.timerId = null;
    this.remaining = this.time;
    this.timeEnd = null;
    this.active = false;
    this._event = new EventEmitter();
  }

  public get isActive() {
    return this.active;
  }

  public get getRemainingPercent() {
    return ((this.remaining / this.time) * 100) ^ 0;
  }

  public get getRemaining() {
    return this.remaining;
  }

  public start() {
    this.active = true;
    this.timeEnd = new Date().getTime() + this.remaining;
    this._event.emit(TIME_START, { ...this, type: TIME_START });
    this.timerId = setTimeout(() => {
      clearTimeout(this.timerId!);
      this.active = false;
      this._event.emit(TIME_END, { ...this, type: TIME_END });
    }, this.remaining);
  }

  public stop() {
    if (this.timeEnd && this.active) {
      clearTimeout(this.timerId!);
      this.remaining = this.timeEnd - new Date().getTime();
      this.active = false;
      this.timeEnd = null;
      this._event.emit(TIME_STOP, { ...this, type: TIME_STOP });
    } else {
      throw Error("The timer is not running.");
    }
  }

  public on(event: string | string[], ctx: any) {
    this._event.on(event, ctx);
  }

  public reset() {
    clearTimeout(this.timerId!);
    this.remaining = this.time;
    this.active = false;
    this.timeEnd = null;
  }
}
