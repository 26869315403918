import "./lib/polyfill";
import EventEmitter from "./core/event-emitter";
import defaultOptions, { IDefaultOptions } from "./defaults";
import Notify, { INotify } from "./components/notify";
import { mergeOptions, IOptions } from "./utils/object";
import { capitalize } from "./utils/string";
import { SHOW_BEFORE, SHOW_AFTER, REMOVE } from "./constants/events";

export default class Notifications {
  private readonly options: IDefaultOptions;
  private notifications: Notify[];
  private targetWrap: HTMLDivElement | null;
  private _event: EventEmitter;

  constructor(options?: IOptions | undefined) {
    this.options = mergeOptions(defaultOptions, options) as IDefaultOptions;
    this.targetWrap = null;
    this.notifications = [];
    this._event = new EventEmitter();
  }

  public init() {
    this.targetWrap = document.querySelector(
      `.${this.options.className!.wrap}.${this.genClassName}`
    );

    if (!this.targetWrap) {
      this.createWrap();
    }
  }

  public show(notify: INotify) {
    if (this.options.once) {
      const redo = this.notifications.some(
        elem => JSON.stringify(elem.notify) === JSON.stringify(notify)
      );

      if (redo) { return; }
    }

    this._event.emit(SHOW_AFTER, { ...this, type: SHOW_AFTER });

    this.notifications.push(new Notify(notify, this.options, this));

    if (this.notifications.length) {
      if (!this.targetWrap) {
        this.init();
      }

      if (this.targetWrap) {
        const notifyElem = this.notifications[this.notifications.length - 1];
        notifyElem.mount(this.targetWrap);
        this._event.emit(SHOW_BEFORE, {
          ...this,
          type: SHOW_BEFORE,
          createElement: notifyElem
        });
      }
    }
  }

  public on(event: string, ctx: any) {
    this._event.on(event, ctx);
  }

  private remove(id: string) {
    const idx = this.notifications.findIndex(elem => elem.id === id);

    if (idx >= 0) {
      if (this.notifications[idx].isActive) {
        this.notifications[idx].unmount();
      } else {
        this.notifications.splice(idx, 1);
      }
    } else {
      throw new Error("There is no element with this id");
    }

    if (!this.notifications.length && this.targetWrap) {
      this.targetWrap.remove();
      this.targetWrap = null;
    }

    this._event.emit(REMOVE, { ...this, type: REMOVE });
  }

  private createWrap(): void {
    this.targetWrap = document.createElement("div");
    this.targetWrap.classList.add(this.options.className.wrap);
    this.targetWrap.classList.add(this.genClassName);
    this.targetWrap.style.cssText = this.genStyles;

    document.body.append(this.targetWrap);
  }

  private get genClassName() {
    const [x, y] = this.options.position;
    return this.options.className.wrap + "-" + x + capitalize(y);
  }

  private get genStyles(): string {
    const [x, y] = this.options.position;
    const { width, zIndex } = this.options.styles;

    const posX =
      x === "center"
        ? `left: 50%; transform: translateX(-50%);`
        : x === "left"
        ? `left: 0;`
        : `right: 0;`;

    const posY = y === "top" ? `top: 0;` : `bottom: 0;`;

    return `
      box-sizing: border-box;
      position:fixed;
      width:${width === "max" ? "100%" : width + "px"};
      ${posX}
      ${posY}
      z-index: ${zIndex}`;
  }
}
