import { Module } from "vuex";

import { RootState } from "@/store/root.interface";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { defaultColumns } from "./table.defaults";
import { ITableState } from "./table.interfaces";

import { MIN_CONTAINER_WIDTH } from "@/constants";

export const tableState: Module<ITableState, RootState> = {
  namespaced: true,
  state: {
    containerWidth: {
      app: MIN_CONTAINER_WIDTH,
      preapp: MIN_CONTAINER_WIDTH,
      certificates: MIN_CONTAINER_WIDTH
    },
    columns: JSON.parse(JSON.stringify(defaultColumns)),
    contentType: "app"
  },
  mutations,
  actions,
  getters
};