import {
  IDefaultOptions,
  NotifyType,
  Timing,
  NotifyPositionX,
  NotifyPositionY,
  AnimateIn,
  AnimateOut,
  AddNotify,
  StyleSides,
  ITemplate
} from "../defaults";

export const isObject = (obj: object) =>
  obj instanceof Object && !(obj instanceof Array);

export interface IOptions {
  type?: NotifyType | string;
  progress?: boolean;
  once?: boolean;
  progressOptions?: {
    position?: "top" | "bottom";
    color?: string;
    easing?: Timing;
  };
  position?: [NotifyPositionX, NotifyPositionY];
  duration?: number;
  animation?: boolean;
  animationOptions?: {
    duration?: number | string;
    animateIn?: AnimateIn | string;
    animateOut?: AnimateOut | string;
  };
  addNotify?: AddNotify;
  styles?: {
    width?: number | string;
    zIndex?: number;
    boxShadow?: string;
    margin?: StyleSides;
    padding?: StyleSides;
    borderRadius?: StyleSides;
    textAlign?: "left" | "right" | "center";
    typeStyle?: {
      success?: string;
      warning?: string;
      error?: string;
      info?: string;
    };
  };
  className?: {
    wrap?: string;
    main?: string;
    progress?: string;
  };
  templates?: ITemplate[];
}

export const mergeOptions = (
  defaults: IDefaultOptions,
  settings: IOptions | undefined
): IOptions => {
  const object: IOptions = Object.assign({}, defaults, settings);

  const mergeObject = (obj: any, objEnd: any, _n: number = 0): object => {
    for (const key in obj) {
      if (isObject(obj[key]) && objEnd[key]) {
        objEnd[key] = Object.assign({}, obj[key], objEnd[key]);

        mergeObject(obj[key], objEnd[key], _n++);
      }
    }
    return objEnd;
  };

  return mergeObject(defaults, object) as IOptions;
};
