import { TRoleName } from "./authorization.interfaces";

export const ROLES: {[key: string]: {name: TRoleName}} = {
  ChiefManager: {
    name: "ChiefManager"
  },
  ManagerPartner: {
    name: "ManagerPartner"
  },
  Default: {
    name: "Default"
  }
};
