import Vue from "vue";
import { fetch } from "@/services/api";
import { ActionTree } from "vuex";
import { isEqual } from "lodash";
import {app} from "@/main";
import cookies from "js-cookie";
import {POSITION} from "vue-toastification";

import { RootState } from "@/store/root.interface";
import { IApplicationListState } from "./application-list.interfaces";
import { DEFAULT_FILTER } from "./application-list.defaults";
import { IFilter } from "./application-list.interfaces";

import download from "downloadjs";

import {
  SET_ACTIVE_PAGE,
  SET_SIZE_PAGES,
  SET_ARCHIVE,
  SET_FILTER_CONTROL,
  SET_TOTAL_PAGES,
  SET_APPLICATIONS_LIST,
  SET_IS_LOADED,
  SET_IS_OPEN_FILTER,
  SET_SAVE_FILTER_CONTROL,
  SET_IS_CHIEF_MANAGER,
  SET_IS_MANAGER_PARTNER,
  SET_FILTER_MANAGERS,
  SET_FILTER_ORGANIZATIONS,
} from "./application-list.mutations";

import { SET_NEW_PREAPP_NUM } from "@/store/root.constants";

export const actions: ActionTree<IApplicationListState, RootState> = {
  changePage({ commit, state, dispatch }, page: number) {
    if (state.activePage !== page) {
      commit(SET_ACTIVE_PAGE, page);
      dispatch("getInitialApplication", true);
    }
  },

  changeSizePage({ commit, state, dispatch }, sizePage: number) {
    if (state.sizePage !== sizePage) {
      commit(SET_SIZE_PAGES, sizePage);
      dispatch("getInitialApplication", true);
    }
  },

  setArchive({ commit }, val: boolean) {
    commit(SET_ARCHIVE, val);
  },

  setActivePage({ commit }, num: number) {
    commit(SET_ACTIVE_PAGE, num);
  },

  async getInitialApplication({ commit, state, getters }) {
    let errorMessage: string = "";
    let appList: any[] = [];

    Object.keys(state.saveFilterControls).forEach(name => {
      commit(SET_FILTER_CONTROL, {
        name,
        value: state.saveFilterControls[name]
      });
    });
    app && (app as any).$modal.show("loader");
    try {
      const res = await fetch.get(
        `api/InitialApplication/${state.sizePage}/${state.activePage}${getters.getFilter}`
      );

      if (res.status === 200) {
        const {
          applicationList,
          totalPages, 
          currentPage,
          isChiefManager,
          newPreAppNum,
          isManagerPartner,
          filterManager,
          filterOrganizations,
          lastComments,
          lastManagerComments,
          smevStatuses,
        } = res.data;

        commit(SET_TOTAL_PAGES, totalPages);

        if (currentPage !== state.activePage) {
          commit(SET_ACTIVE_PAGE, currentPage);
        }

        commit(SET_IS_CHIEF_MANAGER, isChiefManager);
        commit(SET_IS_MANAGER_PARTNER, isManagerPartner);
        commit(SET_FILTER_MANAGERS, filterManager);
        commit(SET_FILTER_ORGANIZATIONS, filterOrganizations);

        if (!!applicationList) {
          appList = applicationList;

          if (!!lastComments && !!lastManagerComments) {
            appList = appList
              .map((app: any) => {
                const lastComment = lastComments
                  .find((comm: any) => comm.id == app.id)?.comment;
                const lastManagerComment = lastManagerComments
                  .find((comm: any) => comm.id == app.id)?.comment;
  
                return {
                  ...app,
                  comment: lastComment || null,
                  managerComment: lastManagerComment || null
                };
            });
          } else {
            errorMessage = errorMessage + "\nКомментарии к заявкам не были загружены и не могут быть отображены в таблице.";
          }
          
          if (!!smevStatuses) {
            appList = appList
              .map((app: any) => {
                const statusSMEV = smevStatuses
                  .find((item: any) => item.appId == app.id)?.statusSMEV;
  
                return {
                  ...app,
                  statusSMEV: statusSMEV || null,
                };
            });
          } else {
            errorMessage = errorMessage + "\nСМЭВ статусы к заявкам не были загружены и не могут быть отображены в таблице.";
          }
        } else {
          errorMessage = errorMessage + "\nСписок заявок не был загружен и не может быть отображён в таблице.";
        }
        
        commit(SET_APPLICATIONS_LIST, appList);
        
        this.commit(SET_NEW_PREAPP_NUM, newPreAppNum);
        if(newPreAppNum!=0 && cookies.get("_new_preapp_shown")!=="true"){
          (app as any).$modal.show("dialog", {
            title: "Внимание",
            text: "У вас есть предзаявки со статусом \"Новая предзаявка\"",
            buttons: [
                {
                    title: "ЗАКРЫТЬ",
                    default: true,
                    handler: () => {
                        (app as any).$modal.hide("dialog");
                    },
                },
                {
                  title: "ПЕРЕЙТИ",
                  default: true,
                  handler: () => {
                    (app as any).$modal.hide("dialog");
                    (app as any).$router.push('/preapplication/list');
                  },
              },
            ],
          });
        }

        cookies.set("_new_preapp_shown","true");
        commit(SET_IS_LOADED, true);
      }
    } catch (err) {
      throw err;
    } finally {
      app && (app as any).$modal.hide("loader");
      if (errorMessage) {
        Vue.$toast.error("Ошибка" + errorMessage, {
          position: POSITION.TOP_LEFT,
          timeout: 180000,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    }
  },

  toggleFilter({ state, commit }, val?: boolean) {
    commit(SET_IS_OPEN_FILTER, val !== undefined ? val : !state.isOpenFilter);
    if (!state.isOpenFilter) {
      if (!isEqual(state.filterControls, state.saveFilterControls)) {
        Object.keys(state.saveFilterControls).forEach(name => {
          commit(SET_FILTER_CONTROL, {
            name,
            value: state.saveFilterControls[name]
          });
        });
      }
    }
  },

  inputHandler({ commit }, evt: any) {
    let value;
    if (evt.target.type === "checkbox") {
      value = evt.target.checked;
    } else if (["managers", "organizations"].includes(evt.target.name)) {
      value = [...evt.target.value];
    } else {
      value = evt.target.value;
    }

    commit(SET_FILTER_CONTROL, {
      name: evt.target.name,
      value
    });
  },

  clearFilter({ commit }) {
    Object.keys(DEFAULT_FILTER).forEach(name => {
      if (name !== "search") {
        commit(SET_FILTER_CONTROL, {
          name,
          value: DEFAULT_FILTER[name]
        });

        commit(SET_SAVE_FILTER_CONTROL, {
          name,
          value: DEFAULT_FILTER[name]
        });
      }
    });
  },

  clearFilterHandler({ state, commit, dispatch }) {
    dispatch("clearFilter");
    dispatch("cleanFilterInLocalStorage");
    commit(SET_IS_OPEN_FILTER, false);
    dispatch("getInitialApplication");
  },

  applyFilter({ state, dispatch, commit }) {
    if (!isEqual(state.filterControls, state.saveFilterControls)) {
      Object.keys(state.filterControls).forEach(name => {
        commit(SET_SAVE_FILTER_CONTROL, {
          name,
          value: state.filterControls[name]
        });
      });
      dispatch("saveFilterToLocalStorage");
      dispatch("getInitialApplication");
    }

    commit(SET_IS_OPEN_FILTER, false);
  },

///////////////////////////////////////////////////////
// Save to CSV file
async getReport({ commit, state, getters }) {
  Object.keys(state.saveFilterControls).forEach(name => {
    commit(SET_FILTER_CONTROL, {
      name,
      value: state.saveFilterControls[name]
    });
  });

  try {
    // const res 
    const { status, data, headers } 
    = await fetch.get( `api/InitialApplication/report${getters.getFilter}`, { responseType: "blob" } );

    if (status === 200) 
    {
      var d = new Date(Date.now());
      var fName = "Список заявок " + d.toLocaleDateString() + " " + d.toLocaleTimeString() + ".csv";

      try
      {
         fName = decodeURI(headers["content-disposition"].match(/filename=(.*);/)[1]);
      }
      catch{}

      download(
        data,
        fName,
        headers["content-type"],
      );
    }
  } catch (err) {
    throw err;
  }
},

saveFilterToLocalStorage({state}) {
  const filter = {...state.saveFilterControls };
  delete filter["search"];
  const stringifiedFilter = JSON.stringify(filter);
  window.localStorage.setItem("appFilter", stringifiedFilter);
},

parseFilterFromLocaleStorage({commit}) {
  const stringifiedFilter: null | string = window.localStorage.getItem("appFilter");
  if (stringifiedFilter) {
    const parsedFilter: IFilter = JSON.parse(stringifiedFilter);
    Object.keys(parsedFilter).forEach(name => {
      let value = parsedFilter[name];
      commit(SET_SAVE_FILTER_CONTROL, {name, value});
    });
  }
},

cleanFilterInLocalStorage() {
  window.localStorage.setItem("appFilter", "");
}

};
