import { MutationTree } from "vuex";

import { IApplicationListState } from "./application-list.interfaces";

export const SET_ARCHIVE = "SET_ARCHIVE";
export const SET_IS_LOADED = "SET_IS_LOADED";
export const SET_SIZE_PAGES = "SET_SIZE_PAGES";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SET_FILTER_CONTROL = "SET_FILTER_CONTROL";
export const SET_IS_OPEN_FILTER = "SET_IS_OPEN_FILTER";
export const SET_FILTER_MANAGERS  = "SET_FILTER_MANAGERS";
export const SET_IS_CHIEF_MANAGER  = "SET_IS_CHIEF_MANAGER";
export const SET_APPLICATIONS_LIST = "SET_APPLICATIONS_LIST";
export const CLEAR_APPLICATION_LIST = "CLEAR_APPLICATION_LIST";
export const SET_IS_MANAGER_PARTNER  = "SET_IS_MANAGER_PARTNER";
export const SET_SAVE_FILTER_CONTROL = "SET_SAVE_FILTER_CONTROL";
export const SET_FILTER_ORGANIZATIONS  = "SET_FILTER_ORGANIZATIONS";

export const mutations: MutationTree<IApplicationListState> = {
  [SET_TOTAL_PAGES](state, num: number) {
    state.totalPages = num;
  },
  [SET_ACTIVE_PAGE](state, num: number) {
    state.activePage = num;
  },
  [SET_SIZE_PAGES](state, num: number) {
    state.sizePage = num;
  },
  [SET_APPLICATIONS_LIST](state, applications: []) {
    state.applicationsList = [...applications];
  },
  [SET_IS_LOADED](state, val: boolean) {
    state.isLoaded = val;
  },
  [CLEAR_APPLICATION_LIST](state) {
    state.sizePage = 10;
    state.activePage = 1;
    state.totalPages = null;
  },
  [SET_FILTER_CONTROL](
    state,
    { name, value }: { name: string; value: string | boolean }
  ) {
    state.filterControls[name] = value;
  },
  [SET_SAVE_FILTER_CONTROL](
    state,
    { name, value }: { name: string; value: string | boolean }
  ) {
    state.saveFilterControls[name] = value;
  },
  [SET_IS_OPEN_FILTER](state, val: boolean) {
    state.isOpenFilter = val;
  },
  [SET_ARCHIVE](state, val: boolean) {
    state.isArchive = val;
  },
  [SET_IS_CHIEF_MANAGER](state, val: boolean) {
    state.isChiefManager = val;
  },
  [SET_IS_MANAGER_PARTNER](state, val: boolean) {
    state.isManagerPartner = val;
  },
  [SET_FILTER_MANAGERS](state, managers: []) {
    state.filterManager = [...managers];
  },
  [SET_FILTER_ORGANIZATIONS](state, organizations: []) {
    state.filterOrganizations = [...organizations];
  },
};
