import { HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";
import { BASE_URL_API } from "@/constants";

const URL = `${BASE_URL_API}initialApplicationHub`;
 
export default {
  install (Vue: any) {
    let startedPromise: any = new Promise(() => {});
    let isStarting = true;

    const connection = new HubConnectionBuilder()
      .withUrl(URL, {skipNegotiation: true, transport: HttpTransportType.WebSockets})
      .configureLogging(LogLevel.Information)
      .build();

    connection.serverTimeoutInMilliseconds = 600000; // 10 минут

    const connectionHub = new Vue();
    Vue.prototype.$connectionHub = connectionHub;
    connection.on("StatusChange", (applicationId, newStatusId) => {
      connectionHub.$emit('status-changed', {applicationId, newStatusId})
    });

    connectionHub.requestStatus = (orderId: number | string) => {
      return startedPromise
        .then(() => connection.invoke("ViewApplication", orderId))
        .catch(console.error)
    };

    function start() {
      isStarting = true;
      startHandler();
    }

    function startHandler(): any {
      if (isStarting) {
        startedPromise = connection.start().catch((error: any): any => {
          console.error('Соединение с initialApplicationHub прервано', error);
          return new Promise((resolve, reject) => {
            setTimeout(() => startHandler().then(resolve).catch(reject), 5000);
          });
        })
        return startedPromise;
      }
      return new Promise(() => {});
    }
    connection.onclose(() => startHandler());

    function stop() {
      isStarting = false;
      connection.stop();
    }

    connectionHub.start = start;
    connectionHub.stop = stop;
  }
}
