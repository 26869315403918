import { IFilter } from "./application-list.interfaces";

export const DEFAULT_FILTER: IFilter = {
  fl: false,
  ip: false,
  ur: false,
  search: "",
  statusSendDoc: false,
  statusGenReq: false,
  statusRelease: false,
  statusDone: false,
  statusFail: false,
  statusPayed: false,
  statusWaitingOriginals: false,
  statusClosed: false,
  statusOriginalsIncorrect: false,
  dateCreateFrom: "",
  dateCreateTo: "",
  dateIssueFrom: "",
  dateIssueTo: "",
  datePaymentFrom: "",
  datePaymentTo: "",
  managers: [],
  organizations: [],
  identificationKind: 0
};
