import { MutationTree } from "vuex";
import { IProductsState, IProduct } from "./products.interfaces";

export const SET_PRODUCTS = "SET_PRODUCTS";
export const RESET_PRODUCT = "RESET_PRODUCT";
export const SET_PRODUCTS_SELECTED = "SET_PRODUCTS_SELECTED";
export const REMOVE_PRODUCTS_SELECTED = "REMOVE_PRODUCTS_SELECTED";
export const SET_PRODUCTS_SELECTED_ALL = "SET_PRODUCTS_SELECTED_ALL";

export const mutations: MutationTree<IProductsState> = {
  [SET_PRODUCTS](state, payload: IProduct[]) {
    state.products = [...payload];
  },
  [SET_PRODUCTS_SELECTED](state, payload: number) {
    const productsSelected = [...state.productsSelected, payload];
    state.productsSelected = [...new Set(productsSelected)];
  },
  [SET_PRODUCTS_SELECTED_ALL](state, payload: number[]) {
    const productsSelected = [...state.productsSelected, ...payload];
    state.productsSelected = [...productsSelected];
  },
  [REMOVE_PRODUCTS_SELECTED](state, payload: number) {
    state.productsSelected = state.productsSelected.filter(
      id => payload !== id
    );
  },
  [RESET_PRODUCT](state) {
    state.products = [];
    state.productsSelected = [];
    state.isValid = true;
  }
};
