import moment from "moment";

import up from "./up";


function getFormattedDate (date: Date, format: string) {
  return moment(date).locale("ru").format(format);
}

function fileToBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

function openImgInNewTab(url: string): void {
  let img = new Image();
  img.onload = function() {
      let w = window.open("", '_blank');
      if(w){
          w.document.write(img.outerHTML);
      }
  }
  img.src = url;
};

function base64toBlob(base64Url: string, contentType: string) {
  contentType = contentType || '';
  const sliceSize = 1024;
  const base64Data = base64Url.substr(base64Url.indexOf(',') + 1)
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, {type: contentType});
}

function base64ToFile(name: string, header: string, content: string) {
  const formattedHeader = header.replace(",", "");
  const contentType = formattedHeader.replace("data:", "").replace(";base64", "").trim();
  const blob = base64toBlob(`${formattedHeader}, ${content}`, contentType);
  const fileJS = new File([blob], name, {type: blob.type});
  return {fileJS, contentType};
}

function blobToUrl(blob: Blob, fileName: string) {
  const fileJS = new File([blob], fileName, {type: blob.type});
  return URL.createObjectURL(fileJS);
}

export { up, getFormattedDate, fileToBase64, openImgInNewTab, base64toBlob, base64ToFile, blobToUrl };
