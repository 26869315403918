import { Module } from "vuex";

import { actions } from "./products.actions";
import { mutations } from "./products.mutations";
import { getters } from "./products.getters";

import { IProductsState } from "./products.interfaces";
import { RootState } from "@/store/root.interface";

export const productsState: Module<IProductsState, RootState> = {
  namespaced: true,
  state: {
    isValid: true,
    products: [],
    productsSelected: []
  },
  mutations,
  getters,
  actions
};
