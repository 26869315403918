/**
 * For Notifications
 */
export const SHOW_BEFORE = "show:before";
export const SHOW_AFTER = "show:after";
export const REMOVE = "remove";

/**
 * For Timer
 */
export const TIME_START = "time:start";
export const TIME_STOP = "time:stop";
export const TIME_END = "time:end";

/**
 * For Notify
 */
export const MOUNT_AFTER = "mount:after";
export const MOUNT_BEFORE = "mount:before";
export const UNMOUNT_AFTER = "unmount:after";
export const UNMOUNT_BEFORE = "unmount:before";
