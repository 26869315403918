import { Module } from "vuex";

import {
  DEFAULT_FORM_CONTROLS,
  DEFAULT_UPLOAD_FORM_CONTROLS,
  DEFAULT_ANOTHER_UPLOADS
} from "./application.defaults";

import { IApplicationState } from "./application.interfaces";
import { RootState } from "@/store/root.interface";

import { mutations } from "./application.mutations";
import { getters } from "./application.getters";
import { actions } from "./application.actions";

import Crypto from "@/services/CryptoProWrapper/CryptoProWrapper";

export const crypto: Crypto = new Crypto();
crypto.load();

export const application: Module<IApplicationState, RootState> = {
  namespaced: true,
  state: {
    isLinkError: false,
    isFormValid: null,
    isEdit: false,//null,
    isEditManagerComment: false,
    isEditUpload: null,
    isSubmitedManagerComment: false,
    isSubmited: false,
    isDocumentDownload: false,
    activeTab: 1,
    filesDownload: [],
    fieldsReadonlyKeys: [],
    optionsCountry: [],
    optionsRegion: [],
    optionsIdentificationKind: [{value: 1, label: "Лично"},{value: 2,label: "По действующей ЭП"}],
    optionsIdentificationDocumentTypes: [],
    certificate: [],
    certRequest: null,
    archived: false,
    externalSourcePkcs10: false,
    formControls: { ...DEFAULT_FORM_CONTROLS },
    uploadFormControls: { ...DEFAULT_UPLOAD_FORM_CONTROLS },
    isUploadFormLoaded: true,
    managerId: null,
    orderId: null,
    status: null,
    statusId: null,
    products: [],
    comment: null,
    managerComment: null,
    creationDate: null,
    changeDate: null,
    isDocumentsCollected: false,
    sigCheckStatus: null,
    sigIssuerCN: null,
    isCertificateRequested: false,
    currentCertThumbprint: null,
    certIsValid: false,
    errorMessage: null,
    paymentStatusId: null,
    paymentStatusDescription: null,
    lastPaymentDate: null,
    isUPDrequested: false,
    isSendUPDtoEDM: false,
    edmTypeId: null,
    edmTypes: [],
    hideUtd: false,
    updStatus: null,
    updStatusId: null,
    updocument: null,
    isManagerCommentEditable: false,
    isKKMReceiptLaunchable: false,
    isFNSIssueAvailable: false,
    privateKeyExportPolicy: -1,
    scansFromPreApp: [],
    checkCertInFnsMessage: null,
    needToAttachRevoke: null,
    smevChecks: [],
    anotherUploads: JSON.parse(JSON.stringify(DEFAULT_ANOTHER_UPLOADS)),
    isAnotherUploadsLoaded: false,
    fieldsFromBasisApi: {},
    fnsExistingCerts: [],
    newCertRequest: null,
    signedCertRequest: null,
    newCertRevoke: null,
    signedCertRevoke: null,
    isFilledByOGRN: false,
    requestGuid: null,
    filialClientLink : null,
    qrUrl: null,
    isPdnUsingProhibited: false,
    filialInfo: null,
  },

  mutations,
  actions,
  getters
};
