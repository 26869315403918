import Vue from "vue";
import Vuex, {StoreOptions} from "vuex";


import {application} from "./modules/application";
import {applicationListState} from "./modules/application-list";
import {authorizationState} from "./modules/authorization";
import {productsState} from "./modules/products";
import {cert} from "./modules/cert";
import {comments} from "./modules/comments";
import { tableState } from "./modules/table";

import {RootState} from "./root.interface";
import {actions} from "./root.actions";
import {mutations} from "./root.mutations";


Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    strict: process.env.NODE_ENV !== "production",
    state: {
        updateExists: false,
        registration: null,
        newPreAppNum: 0
    },
    actions,
    mutations,
    modules: {
        authorizationState,
        application,
        applicationListState,
        productsState,
        cert,
        comments,
        tableState
    }
};

export default new Vuex.Store<RootState>(store);
