export enum InitialFileTypes {
  // Файл запроса сертификата
  CertificateRequest,

  // Заявление на выпуск СКП ЭП
  Application,

  // Доверенность на представителя
  Procuration,

  // Счёт на оплату
  Bill,

  // Универсальный передаточный документ (УПД)
  UPD,

  // Клиентский договор на оказание услуг по выпуску СКП ЭП
  ClientAgreement,

  // Печатная форма СКП ЭП
  PrintingForm,

  // Файл сертификата
  KeyCertificate,

  // Архив с документами
  DocumentsZip,

  // Файл подписи архива с документами
  DocumentsZipSig,

  // Файл лицензии КриптоПро 4.0 (годовая)
  CryptoProKey,

  // Файл лицензии КриптоПро 4.0 (бессрочная)
  CryptoProKeyTimeless,

  // Файл паспорта
  Passport,

  // Файл СНИЛС
  Snils = 13,

  // Файл с фото клиента
  Photo = 14,

  //Печатная форма руководства по обеспечению безопасности
  SecurityInstructionForm = 15,

  // Файл подписи архива с документами главным менеджером
  DocumentsZipHeadSig,

  // Дополнительные документы         
  ExtraDocs,
  
  //Заявление на отзыв сертификата
  Revocation = 18,

  //Заявление на сертификацию
  VerificationAgreement = 19,

  //Запрос на сертификат, подписанный присоединенной подписью "ФАМИЛИЯ_request_номерЗаявки.sgn"
  CertificateRequestSigned = 20,

  CertificateRevokeSigned = 21,

  //Распечатанный и подписанный клиентом сертификат"
  ApplicantSignedPrintingForm = 22,

  //Распечатанное и подписанное клиентом руководство по безопасности"
  ApplicantSignedSecurityInstructionForm = 23,

  // Подпись к заявлению на выпуск
  ApplicationSignature = 25,

  //Подписанные документы клиента
  FilialClientSignatures=29,

  //Подписанная печатная форма(клиентом через ЭП)
  PrintingFormSignature = 32,

  // Полис страхования
  InsurancePolicy = 37,
}
