export class CertificateCheckInfo {
    public _SN: String = "";
    public _G:String = "";
    public _SNILS:String = "";
    public _INN:String = "";
    public _applType: number = 1;
    public _OGRN:String = "";
    public _OGRNIP:String = "";
    public _fns: Boolean = false;

    constructor() {
        /**/
      }
}