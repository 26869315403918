const { random, pow } = Math;

export const isColor = (color: string) =>
  color.substring(0, 1) === "#" ||
  color.substring(0, 3) === "rgb" ||
  color.substring(0, 3) === "hsl";

export const generateId = () =>
  pow((random() * new Date().getTime()) ^ 0, 2).toString(32);

export const capitalize = (str: string): string | undefined => {
  if (!str) { return str; }
  return str[0].toUpperCase() + str.slice(1);
};
