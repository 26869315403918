

















import { Vue, Component } from "vue-property-decorator";
import { refreshAccessToken, isAuthenticate } from "@/services/api/fetch";
import cookie from "js-cookie";
import { mapState, mapActions } from "vuex";
import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { getRole } from "@/services/api/fetch";

import Loader from "@/components/UI/Loader.vue";

@Component({
  components: { Loader },
  methods: {
    ...mapActions(["preUpdateApp", "refreshApp"]),
    ...mapActions("authorizationState", [ "setLocationToRedirect", "setRole"])
  }
})
export default class App extends Vue {
  private isLoaded = false;
  private refreshing = false;

  private refreshApp!: () => void;
  private preUpdateApp!: (
    registration: ServiceWorkerRegistration | null
  ) => void;
  private setLocationToRedirect!: (locationToRedirect: string) => void;
  private setRole!: (role: string) => void;

  public created() {
    gsap.registerPlugin(CSSPlugin);

    this.setLocationToRedirect(this.$route.query.redirect as string);

    document.addEventListener(
      "sw:updated",
      (evt: CustomEventInit<ServiceWorkerRegistration>) => {
        this.preUpdateApp(evt.detail!);

        if (
          this.$route.name !== "application" &&
          this.$route.name !== "create-application"
        ) {
          this.refreshApp();
        }
      },
      { once: true }
    );

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) {
          return;
        }

        this.refreshing = true;
        window.location.reload();
      });
    }

    //if (cookie.get("_rt") && !isAuthenticate()) {
      this.handlerLoaderApp();
    //} else {
    //  this.isLoaded = true;
    //}
  }

  public beforeUpdate() {
    //this.setRole(getRole());
  }

  private async handlerLoaderApp() {
    this.isLoaded = false;

    try {
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect as string);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoaded = true;
    }
  }
}
